import { 
    FaReact,
    FaHtml5,
    FaWordpress,
    FaCss3,
    FaSass,
    FaPhp,
    FaGulp,
    FaJs,
    FaGithub,
    FaLess
} from 'react-icons/fa'


const TechsContainer = [
    {
        id: 1,
        label: 'HTML5',
        icon: FaHtml5,
        url: 'https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5'
    },
    {
        id: 2,
        label: 'ReactJS',
        icon: FaReact,
        url:'https://reactjs.org/'
    },
    {
        id: 3,
        label: 'WordPress',
        icon: FaWordpress,
        url: 'https://en-au.wordpress.org/'
    },
    {
        id: 4, 
        label: 'CSS3',
        icon: FaCss3,
        url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3'
    },
    {
        id: 5, 
        label: 'SASS',
        icon:FaSass,
        url: 'https://sass-lang.com/'
    },
    {
        id: 6, 
        label: 'PHP',
        icon: FaPhp,
        url: 'http://www.php.net/'
    },
    {
        id: 7, 
        label: 'Gulp',
        icon: FaGulp,
        url: 'https://gulpjs.com/'
    },
    {
        id: 8, 
        label: 'Javascript',
        icon: FaJs,
        url: 'https://developer.mozilla.org/bm/docs/Web/JavaScript'
    },
    {
        id: 9,
        label: 'Git',
        icon: FaGithub,
        url: 'https://github.com/'
    },
    {
        id: 10, 
        label: 'Less',
        icon: FaLess,
        url: 'http://lesscss.org/'
    },
    {
        id: 11,
        label: 'Webpack',
        url: 'https://webpack.js.org/'
    },
    {
        id: 12,
        label: 'MySQL',
        url: 'https://www.mysql.com/'
    },
    {
        id: 13,
        label: 'Redux',
        url: 'https://redux.js.org/'
    },
    {
        id: 14,
        label: 'Material UI',
        url: 'https://material-ui.com/'
    },
    {
        id: 15,
        label: 'Bootstrap 4',
        url: 'https://getbootstrap.com/'
    },
    {
        id: 16,
        label: 'Jira',
        url: 'https://www.atlassian.com/software/jira'
    },
    {
        id: 17,
        label: 'Asana',
        url: 'https://asana.com/'
    },
    {
        id: 18,
        label: 'Trello',
        url: 'https://trello.com/'
    },


]

export default TechsContainer
