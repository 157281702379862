import React from 'react'
import Header from '../components/layout/Header'
import MainWrapper from '../components/layout/Main'
import Footer from '../components/layout/Footer'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'

import TechsContainer from '../containers/TechsContainer'

import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'

const ChipWithStyles = styled(Chip)`
    margin: 5px;
    border-color: #209cee;

    [class^="MuiAvatar-"],
    .jss201{
        background-color: #209cee;
        color: #FFF;
    }
`

const Paragraph = styled.p`
    text-align: center;
`

export default function Techs() {

    return (
        <div className='outter_wrapper'>
            <Header pageTitle='Techs'/>
            <MainWrapper helperClasses='half centered'> 
                <h1>My tech tools</h1>
                <Paragraph>A few of the tools I use on a daily basis to craft stuff online.</Paragraph>
                <Grid item className="justify-xs-space-evenly">
                    { 
                        TechsContainer.map( tech => {

                            return <ChipWithStyles
                                avatar={ <Avatar> {
                                    tech.icon
                                    ? React.createElement(tech.icon, {}, null ) 
                                    : tech.label.charAt(0)
                                } </Avatar> }
                                key={tech.id} 
                                clickable
                                onClick={ () => window.open(tech.url, '_blank') }
                                label={tech.label}
                                variant="outlined"
                                />
                            } )
                    }
                </Grid>
            </MainWrapper>
            <Footer />

        </div>
    )
}

